import React, { FC, useState, useEffect } from "react";
import { WavySection } from "./wavy-section";
import { colors, ySpaceRem, xSpaceRem, fontStacks } from "../global-style";
import { Center } from "./center";
import { Logo } from "./logo";
import { Link } from "gatsby";
import Headroom from "react-headroom";
import { useLocation } from "@reach/router";
import { css } from "styled-components";

const breakpointPx = 1000;

export const Navbar: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false); // only for narrow navbar
  const [professionalIsExpanded, setProfessionalIsExpanded] = useState(false);

  const location = useLocation();

  const professionalIsActive =
    location.pathname.startsWith("/doelgroepen") &&
    !location.pathname.startsWith("/doelgroepen/inwoners");

  const atlasIsActive = location.pathname.startsWith("/klimaatthemas");

  useEffect(() => {
    setIsExpanded(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isExpanded && professionalIsActive) {
      setProfessionalIsExpanded(true);
    }

    if (!isExpanded) {
      setProfessionalIsExpanded(false);
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleWindowClick = () => {
      setProfessionalIsExpanded(false);
    };

    window.addEventListener("click", handleWindowClick);

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  useEffect(() => {
    const handleKeydown = (e: any) => {
      if (e.keyCode === 27) {
        setProfessionalIsExpanded(false);
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <Headroom
      downTolerance={isExpanded ? 999 : undefined}
      style={{ zIndex: 999 }}
      css={`
        .headroom {
          z-index: 999;
          opacity: 1;
          transition: opacity 0.1s ease;
        }

        .headroom--unpinned {
          opacity: 0;
          pointer-events: none;
        }
      `}
    >
      <WavySection backgroundColor={colors.white} bottom="flipped">
        <Center>
          {/**
           * ==============
           * === NARROW ===
           * ==============
           */}
          <div
            css={`
              display: block;

              ${isExpanded &&
                css`
                  max-height: 100vh;
                  overflow-y: scroll;
                `}

              @media (min-width: ${breakpointPx}px) {
                display: none;
              }
            `}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Link
                  to="/"
                  aria-label="Navigeer naar home"
                  style={{
                    position: "relative",
                    zIndex: 2,
                    width: 160,
                    top: `${0.5 * ySpaceRem}rem`,
                    ...(!isExpanded && { marginBottom: -999 }),
                  }}
                >
                  <Logo idPrefix="narrownav" />
                </Link>

                <div
                  style={{
                    paddingTop: `${1.5 * ySpaceRem}rem`,
                    paddingBottom: `${1 * ySpaceRem}rem`,
                    marginLeft: `${2 * xSpaceRem}rem`,
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "1rem",
                      fontFamily: fontStacks.univiaPro,
                      background: "transparent",
                      border: "none",
                    }}
                    onClick={() => {
                      setIsExpanded(isExpanded => !isExpanded);
                    }}
                  >
                    <div>{isExpanded ? "Sluiten" : "Menu"}</div>
                    <div
                      css={`
                        margin-left: ${0.25 * xSpaceRem}rem;

                        & > div {
                          height: 4px;
                          width: 1.75rem;
                          border-radius: 2px;
                          background-color: ${colors.mediumBlue};
                          transition: all 0.25s ease;
                          transform-origin: left;
                        }

                        & > div + div {
                          margin-top: ${0.25 * ySpaceRem}rem;
                        }

                        ${isExpanded &&
                          css`
                            & > div:first-child {
                              transform: rotate(45deg) scale(0.98);
                            }

                            & > div:nth-child(2) {
                              opacity: 0;
                            }

                            & > div:last-child {
                              transform: rotate(-45deg) scale(0.98);
                            }
                          `}
                      `}
                    >
                      <div />
                      <div />
                      <div />
                    </div>
                  </button>
                </div>
              </div>

              {isExpanded && (
                <div
                  style={{
                    paddingTop: `${ySpaceRem}rem`,
                    paddingBottom: `${2 * ySpaceRem}rem`,
                  }}
                >
                  <nav>
                    <ul
                      css={`
                        & > li {
                          font-size: 1.25rem;
                          padding-top: ${0.5 * ySpaceRem}rem;
                          padding-bottom: ${0.5 * ySpaceRem}rem;
                          padding-left: ${0.25 * xSpaceRem}rem;
                          padding-right: ${0.25 * xSpaceRem}rem;
                          border-bottom: 1px solid ${colors.lightGrey};
                        }

                        a {
                          transition: color 0.25s ease;
                        }

                        a.active {
                          color: ${colors.orange};
                        }
                      `}
                    >
                      <li>
                        <Link to="/" activeClassName="active">
                          Home
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/doelgroepen/inwoners/"
                          activeClassName="active"
                        >
                          Inwoners
                        </Link>
                      </li>

                      <li
                        style={{
                          paddingLeft: 0,
                          ...(professionalIsExpanded && { paddingBottom: 0 }),
                        }}
                      >
                        <button
                          style={{
                            paddingLeft: `${0.25 * xSpaceRem}rem`,
                            background: "none",
                            border: "none",
                            fontSize: "inherit",
                            fontFamily: "inherit",
                            ...(professionalIsActive && {
                              color: colors.orange,
                            }),
                          }}
                          onClick={() => {
                            setProfessionalIsExpanded(
                              professionalIsExpanded => !professionalIsExpanded
                            );
                          }}
                        >
                          Professionals
                        </button>

                        {professionalIsExpanded && (
                          <ul
                            css={`
                              padding-top: ${0.5 * ySpaceRem}rem;

                              & > li {
                                font-size: 1rem;
                                padding-top: ${0.25 * ySpaceRem}rem;
                                padding-bottom: ${0.25 * ySpaceRem}rem;
                                padding-left: ${1 * xSpaceRem}rem;
                                border-top: 1px solid ${colors.lightGrey};
                              }
                            `}
                          >
                            <li>
                              <Link
                                to="/doelgroepen/bedrijven/"
                                activeClassName="active"
                              >
                                Bedrijven
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/doelgroepen/landbouw/"
                                activeClassName="active"
                              >
                                Landbouw
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/doelgroepen/natuurorganisaties/"
                                activeClassName="active"
                              >
                                Natuurorganisaties
                              </Link>
                            </li>

                            {/* <li>
                              <Link
                                to="/doelgroepen/onderwijs/"
                                activeClassName="active"
                              >
                                Onderwijs
                              </Link>
                            </li> */}

                            <li>
                              <Link
                                to="/doelgroepen/zorg/"
                                activeClassName="active"
                              >
                                Zorg
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/doelgroepen/dorp-wijk-buurt/"
                                activeClassName="active"
                              >
                                Dorp, wijk &amp; buurt
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>

                      <li>
                        <Link
                          to="/klimaatthemas/hitte/"
                          activeClassName="active"
                          className={atlasIsActive ? "active" : undefined}
                        >
                          Atlas
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/verhalen/"
                          activeClassName="active"
                          partiallyActive={true}
                        >
                          Verhalen
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/tips/"
                          activeClassName="active"
                          partiallyActive={true}
                        >
                          Tips
                        </Link>
                      </li>

                      <li>
                        <Link to="/over-ons/" activeClassName="active">
                          Over ons
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>

          {/**
           * ============
           * === WIDE ===
           * ============
           */}
          <div
            css={`
              display: none;

              @media (min-width: ${breakpointPx}px) {
                display: block;
              }
            `}
          >
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Link
                to="/"
                style={{
                  position: "relative",
                  zIndex: 2,
                  width: 200,
                  top: `${0.5 * ySpaceRem}rem`,
                  marginBottom: -999,
                }}
              >
                <Logo idPrefix="widenav" />
              </Link>

              <nav
                style={{
                  paddingTop: `${1.5 * ySpaceRem}rem`,
                  paddingBottom: `${1 * ySpaceRem}rem`,
                  marginLeft: `${2 * xSpaceRem}rem`,
                }}
              >
                <ul
                  style={{ display: "flex" }}
                  css={`
                    & > li + li {
                      margin-left: ${1.5 * xSpaceRem}rem;
                    }

                    a {
                      transition: color 0.25s ease;
                    }

                    & > li {
                      transition: transform 0.1s ease;
                    }

                    & > li:hover {
                      position: relative;
                      transform: translateY(-2px);
                    }

                    a.active {
                      color: ${colors.orange};
                    }
                  `}
                >
                  <li>
                    <Link to="/" activeClassName="active">
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link to="/doelgroepen/inwoners/" activeClassName="active">
                      Inwoners
                    </Link>
                  </li>

                  <li
                    style={{ transform: "unset" }}
                    css={`
                      position: relative;
                    `}
                  >
                    <button
                      style={{
                        ...(professionalIsActive && { color: colors.orange }),
                        border: "none",
                        background: "transparent",
                        fontFamily: fontStacks.univiaPro,
                        fontSize: "1rem",
                      }}
                      onClick={e => {
                        e.stopPropagation();
                        setProfessionalIsExpanded(prev => !prev);
                      }}
                      onMouseEnter={() => {
                        setProfessionalIsExpanded(true);
                      }}
                      onMouseLeave={() => {
                        setProfessionalIsExpanded(false);
                      }}
                    >
                      Professionals
                    </button>
                    <nav
                      onMouseEnter={() => {
                        setProfessionalIsExpanded(true);
                      }}
                      onMouseLeave={() => {
                        setProfessionalIsExpanded(false);
                      }}
                      style={{
                        position: "absolute",
                        left: `-${0.5 * xSpaceRem}rem`,
                        zIndex: 2,
                        paddingTop: `${0.25 * ySpaceRem}rem`,
                        display: professionalIsExpanded ? "block" : "none",
                      }}
                    >
                      <ul
                        style={{
                          backgroundColor: colors.orange,
                          color: colors.white,
                          padding: `${0.5 * ySpaceRem}rem ${0.5 *
                            xSpaceRem}rem`,
                          boxShadow: "2px 4px 4px 0 rgba(0,0,0,0.27)",
                        }}
                        css={`
                          li {
                            transition: transform 0.1s ease;
                          }

                          li:hover {
                            transform: translateX(2px);
                          }

                          & > li + li {
                            border-top: 1px solid ${colors.white};
                            margin-top: ${0.25 * ySpaceRem}rem;
                            padding-top: ${0.25 * ySpaceRem}rem;
                          }

                          a.active {
                            color: ${colors.black};
                          }
                        `}
                      >
                        <li>
                          <Link
                            to="/doelgroepen/bedrijven/"
                            activeClassName="active"
                          >
                            Bedrijven
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/doelgroepen/landbouw/"
                            activeClassName="active"
                          >
                            Landbouw
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/doelgroepen/natuurorganisaties/"
                            activeClassName="active"
                          >
                            Natuurorganisaties
                          </Link>
                        </li>

                        {/* <li>
                          <Link
                            to="/doelgroepen/onderwijs/"
                            activeClassName="active"
                          >
                            Onderwijs
                          </Link>
                        </li> */}

                        <li>
                          <Link
                            to="/doelgroepen/zorg/"
                            activeClassName="active"
                          >
                            Zorg
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/doelgroepen/dorp-wijk-buurt/"
                            activeClassName="active"
                          >
                            Dorp, wijk &amp; buurt
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </li>

                  <li>
                    <Link
                      to="/klimaatthemas/hitte/"
                      activeClassName="active"
                      className={atlasIsActive ? "active" : undefined}
                    >
                      Atlas
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/verhalen/"
                      activeClassName="active"
                      partiallyActive={true}
                    >
                      Verhalen
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/tips/"
                      activeClassName="active"
                      partiallyActive={true}
                    >
                      Tips
                    </Link>
                  </li>

                  <li>
                    <Link to="/over-ons/" activeClassName="active">
                      Over ons
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </Center>
      </WavySection>
    </Headroom>
  );
};

import { createGlobalStyle, css } from "styled-components";

/**
 * Grid display flags
 */

const yGridFull = false;
const yGridHalve = false;

/**
 * Colors
 */

export const colors = {
  black: "#000a12",
  white: "#fff",
  lightGrey: "#c5c5c5",
  lightestGrey: "#e8e8e8",
  mediumGrey: "#969696",
  darkGrey: "#353535",
  lightBlue: "#6fb3dc", // isn't this just a lighter version of medium blue? can't we encode this in some way?
  lightestBlue: "#dbecf6",
  mediumBlue: "#2e7cb8",
  darkBlue: "#015ea5",
  orange: "#ee7203",
};

/**
 * Typography
 */

export const targetBaseFontSizePx = 18;
const targetBaseLineHeightPx = 28;

const defaultBaseFontSizePx = 16;
export const baseFontSizeRem = targetBaseFontSizePx / defaultBaseFontSizePx;
export const baseLineHeightRem = targetBaseLineHeightPx / targetBaseFontSizePx;

export const fontStacks = {
  cubano: "cubano, sans-serif",
  univiaPro: "univia-pro, sans-serif",
};

export const measure = "70ch";

/**
 * Grid spacing
 */

export const xSpaceRem = baseLineHeightRem;
export const ySpaceRem = xSpaceRem;

/**
 * Global styles
 */

export const GlobalStyle = createGlobalStyle`${css`
  /**
   * Use a better box model
   */

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /**
   * Reset paddings and margins
   */

  * {
    margin: 0;
    padding: 0;
  }

  /**
   * Set base text style
   */

  :root {
    font-family: ${fontStacks.univiaPro};
    font-weight: 500;
    font-style: normal;
    font-size: ${baseFontSizeRem}rem;
    color: ${colors.black};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /**
   * Set base line height
   */

  body {
    line-height: ${baseLineHeightRem}rem;
  }

  /**
   * Set measure
   */

  * {
    max-width: ${measure};
  }

  html,
  body,
  div,
  header,
  nav,
  main,
  footer,
  section,
  iframe,
  svg,
  img,
  input,
  canvas,
  button {
    max-width: none;
  }

  /**
   * Reset list styles
   */

  ul,
  ol,
  dd {
    list-style: none;
  }

  /**
   * Set image styles
   */

  img {
    display: block;
    max-width: 100%;
  }

  /**
   * Set SVG styles
   */

  svg {
    display: block;
  }

  /**
   * Reset link styles
   */

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  /**
   * Hide horizontal overflow
   */

  body {
    overflow-x: hidden;
  }

  /**
   * Display grid lines
   */

  body {
    position: relative;
  }

  body:before {
    content: "";
    z-index: 99999;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
    background-size: 100% ${ySpaceRem}rem;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 170, 255, ${yGridFull ? 0.3 : 0}) 1px,
      transparent 1px,
      transparent ${0.5 * ySpaceRem}rem,
      rgba(0, 170, 255, ${yGridHalve ? 0.2 : 0}) ${0.5 * ySpaceRem}rem,
      transparent calc(${0.5 * ySpaceRem}rem + 1px),
      transparent ${ySpaceRem}rem
    );
  }
`}`;

import React, { FC, createContext, useState, useEffect } from "react";
import ReactResizeDetector from "react-resize-detector";
import {
  GlobalStyle,
  targetBaseFontSizePx,
  ySpaceRem,
  colors,
  xSpaceRem,
  fontStacks,
} from "../global-style";
import { wavesHeight, wavesWidth } from "./wavy-section";
import { Navbar } from "./navbar";
import Helmet from "react-helmet";
import styled from "styled-components";

type LayoutContext = {
  remInPx: number;
  vwInPx?: number;
  waveClearance: string;
};

export const LayoutContext = createContext<LayoutContext>({} as LayoutContext);

const Layout: FC = ({ children }) => {
  const [showInternetExplorerPopup, setShowInternetExplorerPopup] = useState<
    boolean
  >(
    JSON.parse(
      (typeof localStorage !== "undefined" &&
        localStorage.getItem("snv:internetExplorerPopup")) ||
        "true"
    )
  );

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  const isInternetExplorer =
    isMounted &&
    (navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > -1);

  return (
    <div>
      <GlobalStyle />

      <Helmet>
        <html lang="nl" />

        <script
          type="text/javascript"
          src="https://botsrv.com/qb/widget/KoPqxmzwxgrg5eNl/A7VaDrlXygrB6xgO"
          async
          defer
        />
      </Helmet>

      <ReactResizeDetector
        refreshMode="debounce"
        refreshRate={250}
        handleWidth={true}
        handleHeight={true}
        render={({ width: vwInPx }) => {
          const remInPx = isMounted
            ? parseFloat(
                window.getComputedStyle(document.documentElement).fontSize
              )
            : targetBaseFontSizePx;
          const waveClearance =
            vwInPx !== undefined
              ? `${(Math.ceil(
                  ((vwInPx * (wavesHeight / wavesWidth)) /
                    (ySpaceRem * remInPx)) *
                    2
                ) /
                  2) *
                  ySpaceRem}rem`
              : `${(wavesHeight / wavesWidth) * 100}vw`;
          return (
            <LayoutContext.Provider
              value={{
                remInPx,
                vwInPx,
                waveClearance,
              }}
            >
              {isInternetExplorer && showInternetExplorerPopup && (
                <div>
                  <div
                    style={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      backgroundColor: colors.black,
                      opacity: 0.8,
                      zIndex: 9998,
                    }}
                  />

                  <div
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      position: "fixed",
                      backgroundColor: colors.white,
                      zIndex: 9999,
                      maxWidth: "30rem",
                      padding: `${xSpaceRem}rem`,
                      borderRadius: `${xSpaceRem}rem`,
                      boxShadow: "0 0 14px 0 rgba(0,0,0,0.5)",
                    }}
                  >
                    <p style={{ marginBottom: `${ySpaceRem}rem` }}>
                      Wij raden u aan om een andere browser te gebruiken. De
                      weergave en functionaliteiten van deze website werken niet
                      optimaal in Internet Explorer. Alternatieven zijn
                      bijvoorbeeld Google Chrome of Firefox.
                    </p>

                    <button
                      style={{
                        borderRadius: "1.5rem",
                        border: "none",
                        backgroundColor: colors.orange,
                        fontSize: "1rem",
                        fontFamily: fontStacks.univiaPro,
                        color: colors.white,
                        paddingLeft: `${0.5 * xSpaceRem}rem`,
                        paddingRight: `${0.5 * xSpaceRem}rem`,
                        paddingTop: `${0.25 * ySpaceRem}rem`,
                        paddingBottom: `${0.25 * ySpaceRem}rem`,
                      }}
                      onClick={() => {
                        setShowInternetExplorerPopup(false);
                        localStorage.setItem(
                          "snv:internetExplorerPopup",
                          "false"
                        );
                      }}
                    >
                      Site bekijken
                    </button>
                  </div>
                </div>
              )}

              <SkipToContentLink tabIndex={0} href="#main">
                Ga naar hoofdinhoud
              </SkipToContentLink>

              <Navbar />

              <main id="main" tabIndex={-1}>
                {children}
              </main>
            </LayoutContext.Provider>
          );
        }}
      />
    </div>
  );
};

export default Layout;

const SkipToContentLink = styled.a`
  background-color: ${colors.orange};
  color: ${colors.white};
  border-radius: 0.5rem;
  position: absolute;
  top: ${ySpaceRem}rem;
  left: ${xSpaceRem}rem;
  padding: ${0.5 * ySpaceRem}rem ${xSpaceRem}rem;
  z-index: 99999;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  transform: translateX(calc(-100% - ${xSpaceRem}rem));
  transition: transform 0.3s ease-in-out;

  &:focus-within {
    transform: translateX(0px);
  }
`;

import React, { FC } from "react";
import { xSpaceRem } from "../global-style";

export const centerGutterRem = 0.5 * xSpaceRem;

type Props = {
  hasGutters?: boolean;
  childrenAreCentered?: boolean;
};

export const Center: FC<Props> = props => {
  const { hasGutters = true, childrenAreCentered, children } = props;

  return (
    <div
      style={{
        boxSizing: "content-box",
        maxWidth: `${42 * xSpaceRem}rem`,
        ...(hasGutters && {
          paddingLeft: `${centerGutterRem}rem`,
          paddingRight: `${centerGutterRem}rem`,
        }),
        marginLeft: "auto",
        marginRight: "auto",
        ...(childrenAreCentered && {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }),
      }}
    >
      {children}
    </div>
  );
};

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-over-ons-tsx": () => import("./../src/pages/over-ons.tsx" /* webpackChunkName: "component---src-pages-over-ons-tsx" */),
  "component---src-pages-tips-tsx": () => import("./../src/pages/tips.tsx" /* webpackChunkName: "component---src-pages-tips-tsx" */),
  "component---src-pages-toolbox-tsx": () => import("./../src/pages/toolbox.tsx" /* webpackChunkName: "component---src-pages-toolbox-tsx" */),
  "component---src-pages-verhalen-tsx": () => import("./../src/pages/verhalen.tsx" /* webpackChunkName: "component---src-pages-verhalen-tsx" */),
  "component---src-templates-audience-tsx": () => import("./../src/templates/audience.tsx" /* webpackChunkName: "component---src-templates-audience-tsx" */),
  "component---src-templates-climate-theme-tsx": () => import("./../src/templates/climate-theme.tsx" /* webpackChunkName: "component---src-templates-climate-theme-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-story-tsx": () => import("./../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */),
  "component---src-templates-tip-tsx": () => import("./../src/templates/tip.tsx" /* webpackChunkName: "component---src-templates-tip-tsx" */)
}


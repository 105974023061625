import React, { FC, CSSProperties } from "react";

export const wavesWidth = 1000;
export const wavesHeight = 32;

export type WaveKind = "default" | "flipped";

export const WavySection: FC<{
  top?: WaveKind;
  bottom?: WaveKind;
  backgroundColor: string;
  style?: CSSProperties;
}> = props => {
  const { top, bottom, backgroundColor, children, style } = props;

  const viewBox = `0 0 ${wavesWidth} ${wavesHeight}`;

  const svgContents = (
    <path
      d="M0 19C29.338 6.333 69.338 0 120 0c110 0 164 29 207 29 92 0 210-24 274-24 110 0 216 20 306 20 20.667 0 51.667-7.333 93-22v29H0V19z"
      fill={backgroundColor}
    />
  );

  return (
    <div
      style={{
        ...style,
        backgroundColor,
        position: "relative",
        zIndex: 1,
      }}
    >
      {top && (
        <svg
          viewBox={viewBox}
          style={{
            position: "absolute",
            zIndex: 1,
            bottom: "calc(100% - 1px)",
            transform: `scaleX(${top === "flipped" ? -1 : 1})`,
          }}
        >
          {svgContents}
        </svg>
      )}

      {children}

      {bottom && (
        <svg
          viewBox={viewBox}
          style={{
            position: "absolute",
            zIndex: 1,
            top: "calc(100% - 1px)",
            transform: `rotate(180deg) scaleX(${
              bottom === "flipped" ? -1 : 1
            })`,
          }}
        >
          {svgContents}
        </svg>
      )}
    </div>
  );
};
